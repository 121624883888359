import React, {Component} from "react";
import './Twitter.css';
import Helmet from "react-helmet";

class Twitter extends Component{

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        document.body.appendChild(script);
    }
    render(){
        return (
            <div>
                <div className="main-header-container">
                    <div className="main-heading">
                        <h2 id="tweets">Sociala medier</h2>
                        <p>
                            Följ <a href="https://twitter.com/TiebreakerAB">Tiebreaker</a> på sociala medier.
                            Vi håller oss uppdaterade och bevakar kontinuerligt det som händer i branchen.
                        </p>
                    </div>
                </div>
                <div className="tweets-container">
                    <div className="tweets-table">
                        <a className="twitter-timeline" data-theme="light"
                           data-link-color="#247abe"
                           href="https://twitter.com/TiebreakerAB?ref_src=twsrc%5Etfw">Tweets
                            by TiebreakerAB</a>
                    </div>
                </div>
            </div>)
    }
}
export default Twitter;
