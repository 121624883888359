import React, {Component} from 'react';
import './Footer.css'
import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import logo from '../../img/symbol-white-transparent.png';

class Footer extends Component {
	render() {
		return (
			<footer id="contact" className="footer-box light">
				<div className="footer-container">
					<h2>Kontakt</h2>

					<img className={"smallLogo"} src={logo} alt="Tiebreaker vit logotyp"/>
					<div>
						<h3>Tiebreaker Consulting AB</h3>
						<h3>info@tiebreaker.se</h3>
						<h3>073-6542110</h3>
					</div>

					<p className="footer-icon">
						<span>
							<a className="footer-link"
								 href="https://www.twitter.com/TiebreakerAB"
								 target="_blank"
								 rel="noopener noreferrer">
							<FontAwesomeIcon icon={faTwitter}/>
							</a>
						</span>
						<span>
							<a className="footer-link"
								 href="https://www.linkedin.com/company/tiebreaker"
								 target="_blank"
								 rel="noopener noreferrer">
									<FontAwesomeIcon icon={faLinkedin}/>
							</a>
						</span>
					</p>
				</div>
			</footer>
		)
	}
}

export default Footer