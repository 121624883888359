import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
/**
 * This is the layout of all pages.
 * @param children that should be rendered within the layout.
 * @returns {*}
 * @constructor
 */
const Layout = ({ children }) => (
    // Get the sites title
   <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div className={"content"}>
          {children}
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
