import React, {Component} from 'react'
import './Main.css'
import {SystemDevelopment,	ContinuousDelivery,	Testing,	Mentoring,	Architecture,	Team} from '../components/Skills'
import {library} from '@fortawesome/fontawesome-svg-core'
import sanfransisco from './../img/san_fransisco.jpg'
import Twitter from "./twitter/Twitter";
import {
	faCheck,
	faCloud,
	faSyncAlt,
	faLaptopCode,
	faAt,
	faPhone,
	faArrowRight,
	faBars,
	faUserSecret,
	faComments,
	faPeopleCarry,
	faDatabase, faHandshake, faHelicopter
}
	from '@fortawesome/free-solid-svg-icons'

// https://fontawesome.com/icons?d=gallery
library.add(
	faCheck,
	faCloud,
	faSyncAlt,
	faLaptopCode,
	faAt,
	faPhone,
	faArrowRight,
	faBars,
	faUserSecret,
	faComments,
	faPeopleCarry,
	faDatabase,
	faHandshake,
	faHelicopter);


class Main extends Component {
	render() {
		return (
			<div>
				<div className="main-header-container">
					<div className="main-heading">
						<h1 id="about">Om Tiebreaker Consulting</h1>
						<div>
							<p>
								Tiebreaker Consulting är ett Stockholmsbaserat konsultbolag som
								brinner för systemutveckling.
								Vi jobbar i huvudsak med webbutveckling och hjälper kunder
								utveckla och förvalta frontend och backend applikationer.
							</p>
							<p>
								Vi är specialister inom
								Java, {this.javaEE()}, {this.microprofile()} och {this.spring()} och har lång erfarenhet
								av att bygga och förvalta transaktionsintensiva system
								inom finans och resebranchen.
							</p>

							<p>
								På uppdrag ingår vi ofta i team som jobbar med hela stacken och
								tar ansvar för leveransen.

								Tekniker vi ofta använder är modern JavaScript med ramverk
								som {this.react()} och {this.angular()} i
								klienten samt Java på server sidan.
							</p>
						</div>
					</div>
				</div>
				{/* Header section */}
				<div className="main-header-container">
					<div className="main-heading">
						<h2 id="services">Tjänster</h2>
						<div>
							Vi har lång erfarenhet av att leverera IT system och
							trivs som bäst när vi tillsammans med kund får vara med och skapa
							hållbara och långsiktiga lösningar.
						</div>
					</div>
				</div>
				{/* Content */}
				<div className="main-container">
					<div className="main-container-services">
						<div className="row">
							<SystemDevelopment/>
							<ContinuousDelivery/>
						</div>
						<div className="row">
							<Testing/>
							<Team/>
						</div>
						<div className="row">
							<Architecture/>
							<Mentoring/>
						</div>
					</div>
				</div>

               {/*this.news()*/}
                <Twitter/>

			</div>)
	};

	news = () => {
		return (<div className="main-header-container">
			<div className="main-heading">
				<h2 id="news">Aktuellt</h2>
				{/* news content */}
				<div className={"news-container"}>
					<div className={"news-container-item"}>
						<img src={sanfransisco}
								 alt="Bild på Golden Gate i San Fransisco"
								 className={"image"}/>
						<h3>Nyheter från CodeOne 2019</h3>
						<time className="date">9 oktober 2019</time>
						Tiebreaker har varit i San Fransisco och bevakat årets
						upplaga av konferensen {this.codeOne()}.
                        Läs mer om de senaste trenderna från konferensen i vår rapport.
						<p>
							<a href="/pages/articles/oracle_code_one_2019">Rapport från CodeOne</a>
						</p>
					</div>
				</div>
			</div>
		</div>)
	}

	test = () => {
	    return <span></span>
    }
	codeOne = () => {
		return <a href="https://www.oracle.com/code-one/" target="_blank" rel="noopener noreferrer">Oracle CodeOne</a>
	};
	angular = () => {
		return <a href="https://angularjs.org/" target="_blank" rel="noopener noreferrer">AngularJS</a>
	};

	react = () => {
		return <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">ReactJS</a>
	};

	spring = () => {
		return <a href="https://spring.io/" target="_blank"
							rel="noopener noreferrer">Spring</a>
	};

	javaEE = () => {
		return <a href="https://jakarta.ee/" target="_blank"
							rel="noopener noreferrer">Java EE/Jakarta EE</a>
	};
	microprofile = () => {
		return <a href="https://microprofile.io/" target="_blank"
							rel="noopener noreferrer">Microprofile</a>
	};

	static renderInformation() {
		return <div className="main-header-container">
			<div className="main-heading">
				<h1 id="information">Kompetens</h1>
				<div>
					<p>
					Hur håller man sig ajour med den senste tekniken?
					</p>
			</div>
			</div>
		</div>;
	}
}

export default Main

