import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, image, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =  description || data.site.siteMetadata.description;
        const metaImage = image || "https://www.tiebreaker.se/icons/icon-256x256.png";
        const url = "https://www.tiebreaker.se/"; // TODO: not possible to acces document.URL
        return (
          <Helmet
            htmlAttributes={{lang,}}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription
              },
              {
                property: `og:title`,
                content: title
              },
              {
                property: `og:description`,
                content: metaDescription
              },
              {
                property: `og:type`,
                content: `website`
              },
             {
                property: `og:image`,
                content: metaImage
             },
            {
                property: `og:url`,
                content: url
            },
              {
                name: `twitter:card`,
                content: `summary_large_image`
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author
              },
              {
                name: `twitter:title`,
                content: title
              },
              {
                name: `twitter:description`,
                content: metaDescription
              },
              {
                 name: `twitter:image`,
                 content: metaImage
              },
              {
                name: "format-detection",
                content:"telephone=no"
              }
            ]
            .concat(meta)}
          >
            <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  "legalName": "Tiebreaker Consulting AB",
                  "knowsAbout": "Java, JavaScript, Web development",
                  "url": "https://www.tiebreaker.se",
                  "sameAs": [
                    "https://www.twitter.com/TiebreakerAB",
                    "https://www.linkedin.com/company/tiebreaker"
                  ],
                  "contactPoint": [
                    { "@type": "ContactPoint",
                      "telephone": "+46736542110",
                      "contactType": "sales"
                    }
                  ],
                  "logo": "https://www.tiebreaker.se/icons/icon-144x144.png",
                  "name": "Tiebreaker Consulting",
                  "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Feroxgatan 8",
                    "addressLocality": "Solna",
                    "addressRegion": "Stockholm",
                    "postalCode": "17067",
                    "addressCountry": "SE"
                  }
                }`}
            </script>


            <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"/>


          </Helmet>

        )
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
