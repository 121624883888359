import React from "react"
import {graphql, Link} from "gatsby"
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Img from "gatsby-image"

import '../App.css';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
	faCheck,
	faCloud,
	faSyncAlt,
	faLaptopCode,
	faArrowRight,
	faBars,
	faComments,
	faPeopleCarry,
	faHandshake,
	faHelicopter
}
	from '@fortawesome/free-solid-svg-icons'
import "../components/Main";
import Banner2 from "../components/banner/Banner2";
import Layout from "../components/layout";
import SEO from "../components/seo";
import sanfransisco from "../img/san_fransisco.jpg";
import darkerLogo from "../img/logo-original.png";

// https://fontawesome.com/icons?d=gallery
library.add(
	faCheck,
	faCloud,
	faSyncAlt,
	faLaptopCode,
	faArrowRight,
	faBars,
	faComments,
	faPeopleCarry,
	faHandshake,
	faHelicopter);

//
// Blog template - All .md files will be rendered here....
//
export default function Template({data}) {
	 const {markdownRemark} = data;
	 const {frontmatter, html} = markdownRemark;
	 let imageUrl = null; // Tiebreaker logo
	 if(frontmatter.image){
        imageUrl = frontmatter.image.childImageSharp.fluid.src;
        imageUrl = 'https://www.tiebreaker.se' + imageUrl;
     }
    return (
        <div>
            <SEO title={frontmatter.title}
                 description={frontmatter.description}
                 image={imageUrl}
                 keywords={[frontmatter.description]}/>
            <section>
                <Header/>

                <main>
                   {banner(frontmatter.image)}
                   <div className="blogContainer">
                    <div className="col"/>
                    <div className="blog">
                        <h1>{frontmatter.title}</h1>
                        {blogImage(frontmatter.image, imageUrl)}
                        <div
                            className="blog-post-content"
                            dangerouslySetInnerHTML={{__html: html}}/>
                    </div>
                       <div className="col"/>
                   </div>
                </main>
                <Footer/>
            </section>
        </div>
    )
};

let blogImage = (image, url) => {
    if(image)
        return (<img className="blogImg" src={url}/>);
    else
        return null;
};
let banner = (image) => {
    // TODO: Add banner text and tagline from markdown
    if(image)
        return null;
    else
        return (<Banner2/>);
};

export const pageQuery = () => graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        path
        title
        author
        description
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
