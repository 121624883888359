import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export const SystemDevelopment = () => {
	return (
		<div className="single-skill">
			<div className="icon">
				<FontAwesomeIcon icon="laptop-code"/>
			</div>
			<div className="desc">
				<h3 className="text-uppercase">Systemutveckling</h3>
				<p>
					Vi brinner för systemutveckling i alla dess former. Backend, frontend
					eller
					full stack med Java eller JavaScript som bas.
                    <br/>
					<a href={"pages/system_development"}>
						Läs mer om tjänster inom systemutveckling
					</a>

				</p>
			</div>
		</div>
	)
};

export const ContinuousDelivery = () => {
	return (
		<div className="single-skill">
			<div className="icon">
				<FontAwesomeIcon icon="sync-alt"/>
			</div>
			<div className="desc">
				<h3 className="text-uppercase">Continuous Delivery</h3>
				<p>
					Vi vill riva murarna mellan utveckling, test och drift.
					Nyckeln till framgång är snabba feedbackloopar. Det får man med en
					infrastruktur som möjliggör
					att affärsnytta kan levereras snabbt, enkelt och kontrollerat.
				</p>
			</div>
		</div>
	);
};
export const Testing = () => {
	return (
		<div className="single-skill">
			<div className="icon">
				<FontAwesomeIcon icon="check"/>
			</div>
			<div className="desc">
				<h3 className="text-uppercase">Testautomatisering</h3>
				<p>
					Continuous delivery förutsätter att test kan automatiseras.
					Vi har erfarenhet från att bygga pålitliga, robusta och
					förvaltningsbara tester
					som integreras i leveransprocessen.

				</p>
			</div>
		</div>
	)
};
export const Mentoring = () => {
	return (
		<div className="single-skill">
			<div className="icon">
				<FontAwesomeIcon icon="comments"/>
			</div>
			<div className="desc">
				<h3 className="text-uppercase">
					Mentorskap
				</h3>
				<p>
					Vi delar gärna med oss av vår erfarenhet till kollegor och
					medarbetare.
					Det är givande för båda parter. Sharing is caring och tillsammans blir
					vi
					alltid bättre.

				</p>
			</div>
		</div>
	)
};
export const Architecture = () => {
	return (
		<div className="single-skill">
			<div className="icon">
				<FontAwesomeIcon icon="helicopter"/>
			</div>
			<div className="desc">
				<h3 className="text-uppercase">Systemarkitektur</h3>
				<p>
					Vi hjälper till med att lyfta blicken och se helheten uppifrån.
					En solid systemarkitektur är A och O när man bygger långsiktiga och
					förvaltningsbara lösningar.
				</p>
			</div>
		</div>
	)
};
export const Team = () => {
	return (
		<div className="single-skill">
			<div className="icon">
				<FontAwesomeIcon icon="people-carry"/>
			</div>
			<div className="desc">
				<h3 className="text-uppercase">
					Arbetssätt
				</h3>
				<p>
					Vi gillar det agila arbetsättet men förespråkar effektivitet och sunt
					förnuft
					framför processer.
					Fungerande team som känner ansvar och jobbar effektivt tillsammans
					hittar sina egna vägar och kan ta egna beslut.

				</p>
			</div>
		</div>
	)
};





