import React, {Component} from 'react';
import './Banner.css'

class Banner2 extends Component {
	render() {
		return (
			<div className="banner-container light">
				<div className="centered">
					<div>
						<div className="banner-box-center banner-text">Systemutveckling</div>
						<div className="banner-box-center banner-text-small"><i>ska vara enkelt och produktivt</i></div>
					</div>
				</div>
			</div>
		);
	}
}

export default Banner2