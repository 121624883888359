import React, {Component} from 'react';
import darkerLogo from '../../img/logo-original.png';
import '../../App.css';
import './Header.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class Header extends Component {
	constructor() {
		super();
		this.clickMe = this.clickMe.bind(this);
		this.state = {mobileView: false};
	}

	clickMe = () => {
		let currentState = this.state.mobileView;
		this.setState({mobileView: !currentState})
	};

	render() {
		return (
			<header id="home">
				<div className="header-container">
					<div className="header-logo">
						<a className={"link"} href="/#home" >
							<img className='logo' src={darkerLogo}
									 alt="Tiebreaker Consulting AB logo"/>
						</a>
					</div>
					<div className="header-section">
						<div className="header-links">
							<nav className="links-navigation">
								<a className={"link"} href="/#home">Hem</a>
								<a className={"link"} href="/#services">Tjänster</a>
								<a className={"link"} href="#contact">Kontakt</a>

							</nav>
						</div>
					</div>
					<div className="mobile-header-section">
						<div onClick={this.clickMe}>
							<FontAwesomeIcon icon="bars" className="header-bars"/>
						</div>
					</div>
				</div>
				<div className="mobile-links-navigation">
					{this.state.mobileView && (
						<nav>
							<div><a className={"link"} href="/#home">Hem</a></div>
							<div><a className={"link"} href="/#services">Tjänster</a></div>
							<div><a className={"link"} href="#contact">Kontakt</a></div>
						</nav>)
					}
				</div>
			</header>
		);
	}
}

export default Header;
